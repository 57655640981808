<template>
  <q-card class="l-wrapper">
    <q-scroll-area class="l-grow">
      <q-card-section class="column q-gutter-sm">
        <q-select
          outlined
          label="Request Type"
          v-model="selected.type"
          :options="opt.type"
          @update:model-value="getYear"
        ></q-select>
        <q-select
          outlined
          label="Year"
          v-model="selected.year"
          :options="opt.year"
          @update:model-value="getNewMonth"
        ></q-select>
        <q-select
          outlined
          label="Month"
          v-model="selected.month"
          :options="opt.month"
          @update:model-value="updateChart()"
        ></q-select>
      </q-card-section>
      <q-card-section>
        <div
          id="myApexChart"
          ref="myApexChart"
          style="width:100%; height:300px;"
        ></div>
      </q-card-section>
      <q-card-section>
        <p class="text-h6 text-center">Rata Rata</p>
        <q-markup-table flat bordered separator="horizontal">
          <tr>
            <td>RESPONSE TIME</td>
            <td>:</td>
            <td>{{ summary_avg.time }} Menit</td>
          </tr>
          <tr>
            <td>REQUEST MADE</td>
            <td>:</td>
            <td>{{ summary_avg.req }} Request/Bulan</td>
          </tr>
          <tr>
            <td>REQUEST DONE</td>
            <td>:</td>
            <td>{{ summary_avg.done }} Request/Bulan</td>
          </tr>
          <tr>
            <td>REQUEST DECLINED</td>
            <td>:</td>
            <td>{{ summary_avg.declined }} Request/Bulan</td>
          </tr>
        </q-markup-table>
      </q-card-section>
      <q-card-section class="row content-between">
        <div class="q-ma-sm" style="width:300px">
          <p class="text-h6">Summary</p>
          <q-markup-table
            class="cursor-pointer "
            :class="
              chartData.type == 'summary' ? 'bg-light-blue-6' : 'bg-grey-4'
            "
            flat
            @click="chartData.type = 'summary'"
          >
            <tr>
              <td v-if="selected.type == 'Development'">Dev Request</td>
              <td v-else>Test Request</td>
              <td>:</td>
              <td>{{ selected.month?.summary?.TOTAL }}</td>
            </tr>
            <tr>
              <td>Declined</td>
              <td>:</td>
              <td>{{ selected.month?.summary?.DECLINED }}</td>
            </tr>
            <tr>
              <td>Success</td>
              <td>:</td>
              <td>{{ selected.month?.summary?.SUCCESS }}</td>
            </tr>
            <tr>
              <td>Failed</td>
              <td>:</td>
              <td>{{ selected.month?.summary?.FAILED }}</td>
            </tr>
          </q-markup-table>
        </div>
        <div
          class="q-ma-sm"
          v-if="selected.type == 'Development'"
          style="width:300px"
        >
          <p class="text-h6">Request Type</p>
          <q-markup-table
            flat
            @click="chartData.type = 'dev_type'"
            :class="
              chartData.type == 'dev_type' ? 'bg-light-blue-6' : 'bg-grey-4'
            "
            class="cursor-pointer"
            style="width:300px"
          >
            <tr>
              <td>NEW</td>
              <td>:</td>
              <td>{{ selected.month?.dev_type?.NEW }}</td>
            </tr>
            <tr>
              <td>COMPLAINT</td>
              <td>:</td>
              <td>{{ selected.month?.dev_type?.COMPLAINT }}</td>
            </tr>
            <tr>
              <td>Cost Reduction</td>
              <td>:</td>
              <td>{{ selected.month?.dev_type?.COST_REDUCTION }}</td>
            </tr>
            <tr>
              <td>Reformulation</td>
              <td>:</td>
              <td>{{ selected.month?.dev_type?.REFORMULATION }}</td>
            </tr>
          </q-markup-table>
        </div>
        <div
          class="q-ma-sm"
          v-if="selected.type == 'Development'"
          style="width:300px"
        >
          <p class="text-h6">Top Sales Result</p>
          <q-markup-table
            flat
            class="cursor-pointer"
            style="width:300px"
            @click="chartData.type = 'sales'"
            :class="chartData.type == 'sales' ? 'bg-light-blue-6' : 'bg-grey-4'"
          >
            <tr>
              <td>SALES NAME</td>
              <td>:</td>
              <td>{{ selected.month?.best_sales?.username }}</td>
            </tr>
            <tr>
              <td>GOOD</td>
              <td>:</td>
              <td>{{ selected.month?.best_sales?.grade.GOOD }}</td>
            </tr>
            <tr>
              <td>FAIR</td>
              <td>:</td>
              <td>{{ selected.month?.best_sales?.grade.FAIR }}</td>
            </tr>
            <tr>
              <td>BAD</td>
              <td>:</td>
              <td>{{ selected.month?.best_sales?.grade.BAD }}</td>
            </tr>
          </q-markup-table>
        </div>
        <div
          class="q-ma-sm"
          v-if="selected.type == 'Development'"
          style="width:300px"
        >
          <p class="text-h6">Sales Result</p>
          <q-markup-table
            flat
            class="cursor-pointer"
            :class="
              chartData.type == 'sales_result' ? 'bg-light-blue-6' : 'bg-grey-4'
            "
            style="width:300px"
            @click="chartData.type = 'sales_result'"
          >
            <tr>
              <td>GOOD</td>
              <td>:</td>
              <td>{{ selected.month?.sales_result?.GOOD }}</td>
            </tr>
            <tr>
              <td>FAIR</td>
              <td>:</td>
              <td>{{ selected.month?.sales_result?.FAIR }}</td>
            </tr>
            <tr>
              <td>BAD</td>
              <td>:</td>
              <td>{{ selected.month?.sales_result?.BAD }}</td>
            </tr>
          </q-markup-table>
        </div>
      </q-card-section>
    </q-scroll-area>
  </q-card>
</template>

<script>
import { computed, ref, reactive, inject, watch } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import { useRouter } from "vue-router";

import { useQuasar } from "quasar";
import ApexCharts from "apexcharts";
export default {
  setup() {
    const $http = inject("$http");
    const router = useRouter();
    const $q = useQuasar();

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon Tunggu..." });
        myApexChartInstance.value = new ApexCharts(
          myApexChart.value,
          myApexChartOptions.value
        );
        // console.log(myApexChartInstance.value);
        myApexChartInstance.value.render();
        await getYear();
        chartData.type = "summary";
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    let selected = reactive({
      type: "Development",
      year: null,
      month: null,
    });

    let opt = reactive({
      type: ["Development", "Testing"],
      year: [],
      month: [],
    });

    let getYear = async () => {
      $q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await $http.get(`/summary/year/${selected.type}`);
      let years = [];
      for (let item of resp.data) {
        years.push(item.year);
      }
      opt.year = years;
      if (years.length > 0) {
        selected.year = years[years.length - 1];
        await getNewMonth();
        updateChart();
        // await getMonth();
      }
      $q.loading.hide();
    };

    let summary_avg = ref({});

    let getNewMonth = async () => {
      $q.loading.show({ message: "Mohon Tunggu..." });

      try {
        let resp = await $http.get(
          `summary/month-data/${selected.type}/${selected.year}`
        );
        if (selected.type == "Testing") {
          chartData.type = "summary";
        }

        summary_avg.value = {};

        let avg = {
          time: 0,
          req: 0,
          done: 0,
          declined: 0,
        };

        let c = 0;
        let total_req = 0;
        let total_done = 0;
        let total_declined = 0;
        let total_minute = 0;
        for (let item of resp.data) {
          if (item.month_avg) {
            total_req = total_req + parseInt(item.month_avg.REQUEST);
            total_done = total_done + parseInt(item.month_avg.DONE);
            total_declined = total_declined + parseInt(item.month_avg.DECLINED);
            for (let time of item.month_avg.TIMES) {
              total_minute =
                total_minute +
                moment(time.end).diff(moment(time.start), "minutes");
            }
            c++;
          }
        }
        if (c > 0) {
          avg.time = Math.ceil(total_minute / c);
          avg.req = Math.ceil(total_req / c);
          avg.done = Math.ceil(total_done / c);
          avg.declined = Math.ceil(total_declined / c);
        }

        summary_avg.value = avg;

        opt.month = resp.data;
        if (resp.data.length > 0) {
          selected.month = resp.data[resp.data.length - 1];
        }
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    };

    //chart modification area
    let myApexChart = ref(null);
    let myApexChartInstance = ref(null);
    let myApexChartOptions = ref({
      chart: {
        type: "bar",
      },
      series: [
        {
          name: "DECLINED",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        },
        {
          name: "SUCCESS",
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
        },
        {
          name: "FAILED",
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
        },
      ],
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },
      yaxis: {
        title: {
          text: "Request",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + " Item";
          },
        },
      },
    });

    let chartData = reactive({
      type: null,
    });

    watch(
      () => chartData,
      (state, prevState) => {
        updateChart();
      },
      { deep: true }
    );

    let updateChart = () => {
      let tempChart = JSON.parse(JSON.stringify(myApexChartOptions.value));

      if (!chartData.type) return;
      myApexChartOptions.value.xaxis.categories = [];
      myApexChartOptions.value.series = [];

      if (chartData.type == "summary") {
        myApexChartOptions.value.series.push({ name: "DECLINED", data: [] });
        myApexChartOptions.value.series.push({ name: "SUCCESS", data: [] });
        myApexChartOptions.value.series.push({ name: "FAILED", data: [] });
        for (let month of opt.month) {
          let data = month[chartData.type];
          myApexChartOptions.value.series[0].data.push(data.DECLINED);
          myApexChartOptions.value.series[1].data.push(data.SUCCESS);
          myApexChartOptions.value.series[2].data.push(data.FAILED);

          myApexChartOptions.value.xaxis.categories.push(month.label);
        }
      }

      if (chartData.type == "dev_type") {
        myApexChartOptions.value.series.push({ name: "NEW", data: [] });
        myApexChartOptions.value.series.push({ name: "COMPLAINT", data: [] });
        myApexChartOptions.value.series.push({
          name: "REFORMULATION",
          data: [],
        });
        myApexChartOptions.value.series.push({
          name: "COST REDUCTION",
          data: [],
        });
        for (let month of opt.month) {
          let data = month[chartData.type];
          myApexChartOptions.value.series[0].data.push(data.NEW);
          myApexChartOptions.value.series[1].data.push(data.COMPLAINT);
          myApexChartOptions.value.series[2].data.push(data.REFORMULATION);
          myApexChartOptions.value.series[3].data.push(data.COST_REDUCTION);

          myApexChartOptions.value.xaxis.categories.push(month.label);
        }
      }

      if (chartData.type == "sales") {
        myApexChartOptions.value.series.push({ name: "GOOD", data: [] });
        myApexChartOptions.value.series.push({ name: "FAIR", data: [] });
        myApexChartOptions.value.series.push({ name: "BAD", data: [] });
        for (let sales of selected.month.sales) {
          myApexChartOptions.value.series[0].data.push(sales.grade.GOOD);
          myApexChartOptions.value.series[1].data.push(sales.grade.FAIR);
          myApexChartOptions.value.series[2].data.push(sales.grade.BAD);

          myApexChartOptions.value.xaxis.categories.push(
            `${sales.username} (${sales.nilai} Poin)`
          );
        }
      }

      if (chartData.type == "sales_result") {
        myApexChartOptions.value.series.push({ name: "GOOD", data: [] });
        myApexChartOptions.value.series.push({ name: "FAIR", data: [] });
        myApexChartOptions.value.series.push({ name: "BAD", data: [] });
        for (let month of opt.month) {
          let data = month[chartData.type];
          myApexChartOptions.value.series[0].data.push(data.GOOD);
          myApexChartOptions.value.series[1].data.push(data.FAIR);
          myApexChartOptions.value.series[2].data.push(data.BAD);

          myApexChartOptions.value.xaxis.categories.push(month.label);
        }
      }

      myApexChartInstance.value.updateOptions(myApexChartOptions.value);

      // console.log(tempChart);
      // myApexChartOptions.value = tempChart;
    };

    //unused Func
    let showData = ref({});
    let getMonth = async () => {
      $q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await $http.get(
        `/summary/month/${selected.type}/${selected.year}`
      );
      let list_month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      let months = [];
      for (let item of resp.data) {
        months.push({ label: list_month[item.month - 1], value: item.month });
      }
      opt.month = months;
      if (months.length > 0) {
        selected.month = months[months.length - 1].value;
        await getRequest();
      }
      $q.loading.hide();
    };

    let getRequest = async () => {
      $q.loading.show({ message: "Mohon Tunggu..." });
      try {
        let resp = await $http.get(
          `/summary/request/${selected.type}/${selected.year}/${selected.month}`
        );
        showData.value = resp.data;

        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    };

    return {
      router,
      opt,
      selType: ref("Development"),
      selYear: ref(""),
      selected,
      getYear,
      getMonth,
      getRequest,
      getNewMonth,
      showData,
      myApexChart,
      myApexChartInstance,
      myApexChartOptions,
      chartData,
      updateChart,
      summary_avg,
    };
  },
};
</script>
